import React from "react"
import blogImg from "../img/enjoy-mathematics-cover.svg"

export default class enjoyalgorithmsHeaderPage extends React.Component {
  render() {
    return (
      <div className="max-w-screen-2xl md:mx-auto ">
        <div className="sm:grid sm:grid-cols-12 mt-0 sm:mt-2 px-4 sm:px-6 lg:px-9 xl:px-14 mx-auto sm:gap-4">
          <div className="md:max-w-xl pt-2 sm:pt-4 lg:pt-8  max-w-auto mx-auto sm:col-span-7">
            <h1 className="tracking-wider text-2xl lg:text-3xl 2xl:text-4xl text-cool-gray-900 text-left leading-10 sm:leading-none">
              Enjoy Algorithmic Thinking
            </h1>
            <p className="mt-3 tracking-wider text-base sm:max-w-lg md:max-w-md lg:max-w-lg xl:max-w-xl text-left text-gray-500 sm:mt-5 lg:text-lg xl:text-xl">
              An initiative by enjoyalgorithms! We design content with in-depth
              explanations and help learners develop a dedicated interest in
              math, logic and algorithms.
            </p>
            <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0"></div>
          </div>
          <div className="relative sm:max-w-lg sm:mx-auto sm:max-w-none sm:mx-0 sm:col-span-5 sm:flex sm:items-center">
            <div className="relative w-full mx-auto rounded max-w-md">
              <button className="relative block w-full overflow-hidden rounded focus:outline-none focus:shadow-outline">
                <img
                  className="ml-2 w-11/12 h-11/12"
                  src={blogImg}
                  alt = "Math for computer science"
                />
                <div className="flex items-center justify-center w-full h-full"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
